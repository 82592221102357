import {
  akaVeranstaltungIdColumn,
  endColumn,
  expertsColumn,
  ortKuerzelColumn,
  regionNameColumn,
  startColumn,
  statusColumn,
  TrpcLoadingInfo,
  veranstaltungSapIdColumn,
} from '../../../components/general';
import { generateVeranstaltungListingErrorSnackbar, generateVeranstaltungListingSuccessSnackbar, veranstaltungListingBackendErrorMessage } from '../../../components/snackbars';
import { type AkaVeranstaltungId, type UpdateStatusOfVeranstaltungenOutput, type Veranstaltung, vivaStatus } from '../../../dtos';
import { trpc } from '../../../trpc';
import { type DataGridColDef, localStorageApi, localStorageKeys, printTerminart } from '../../../utils';
import { FehlerCell } from './FehlerCell';
import { content } from './VeranstaltungListing.content';
import { Button, Grid2, Stack } from '@mui/material';
import { DataGridPro, gridClasses, type GridFilterModel, type GridRowSelectionModel, type GridSortItem } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const isAkaVeranstaltungId = (input: string | number): input is AkaVeranstaltungId => typeof input === 'string' && (input.startsWith('V') || input.startsWith('E'));

const veranstaltungsColumns: DataGridColDef<Veranstaltung> = [
  akaVeranstaltungIdColumn(),
  veranstaltungSapIdColumn(),
  startColumn(),
  endColumn(),
  statusColumn(),
  regionNameColumn(),
  ortKuerzelColumn(),
  expertsColumn(),
  {
    field: 'terminart',
    headerName: content.columnLabels.terminart,
    valueGetter: (_value, row) => printTerminart(row.terminart),
    flex: 1,
  },
  {
    field: 'fehler',
    headerName: content.columnLabels.fehler,
    renderCell: FehlerCell,
    flex: 0.5,
    valueGetter: () => 'fehler',
  },
];

type VeranstaltungListingProps = {
  readonly themaId: number;
};

export const VeranstaltungListing: React.FC<VeranstaltungListingProps> = ({ themaId }: VeranstaltungListingProps) => {
  const vaQuery = trpc.veranstaltung.getVeranstaltungenByThemaId.useQuery({ themaId });
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = vaQuery;
  const changeStatusMutation = trpc.planung.updateStatusOfVeranstaltungen.useMutation({
    onError: () => enqueueSnackbar(veranstaltungListingBackendErrorMessage, { variant: 'error' }),
    onSuccess: (mutateResult: UpdateStatusOfVeranstaltungenOutput) => {
      if (mutateResult.length > 0) {
        const successVeranstaltungen = mutateResult.filter((result) => result.successMessages.length > 0);
        if (successVeranstaltungen.length > 0) {
          const snackbar = generateVeranstaltungListingSuccessSnackbar(successVeranstaltungen);
          enqueueSnackbar(snackbar.snackbarNode, snackbar.options);
        }

        const failedVeranstaltungen = mutateResult.filter((result) => result.errorMessages.length > 0);
        if (failedVeranstaltungen.length > 0) {
          const snackbar = generateVeranstaltungListingErrorSnackbar(failedVeranstaltungen);
          enqueueSnackbar(snackbar.snackbarNode, snackbar.options);
        }

        void refetch();
      } else {
        enqueueSnackbar(content.keineVeranstaltungenAenderung, { variant: 'info' });
      }
    },
  });

  const [gridSelection, setGridSelection] = useState<GridRowSelectionModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(localStorageApi.get(localStorageKeys.THEMA_VA_GRID_FILTERMODEL) ?? undefined);
  const [sortModel, setSortModel] = useState<readonly GridSortItem[]>(localStorageApi.get(localStorageKeys.THEMA_VA_GRID_SORTMODEL) ?? [{ sort: 'desc', field: 'start' }]);
  const handleChangeStatusForSelectedVAs = async (newStatus: typeof vivaStatus.ABGESCHLOSSEN | typeof vivaStatus.FREIGEGEBEN): Promise<void> => {
    if (gridSelection.length === 0) {
      enqueueSnackbar(content.keineVeranstaltungenAuswahl, { variant: 'warning' });
      return;
    }

    const akaVeranstaltungIds: AkaVeranstaltungId[] = gridSelection.map((selectedGrid) => {
      if (!isAkaVeranstaltungId(selectedGrid)) {
        throw new Error('Grid selection does not fit input model for changing status of VAs.');
      }

      return selectedGrid;
    });

    await changeStatusMutation.mutateAsync({
      newStatus,
      akaVeranstaltungIds,
    });
  };

  return (
    <Grid2 container>
      <Grid2 size={{ xs: 12 }}>
        <Grid2 container justifyContent="end" sx={{ paddingBottom: 1 }}>
          {gridSelection.length > 0 && (
            <Grid2 size={{ xs: 3 }}>
              <Button disabled={changeStatusMutation.isPending} variant="outlined" onClick={async () => await handleChangeStatusForSelectedVAs(vivaStatus.ABGESCHLOSSEN)}>
                {content.buttons.abgeschlossen}
              </Button>
              <Button disabled={changeStatusMutation.isPending} variant="outlined" onClick={async () => await handleChangeStatusForSelectedVAs(vivaStatus.FREIGEGEBEN)}>
                {content.buttons.freigegeben}
              </Button>
            </Grid2>
          )}
        </Grid2>
        <Stack spacing={1}>
          <TrpcLoadingInfo trpcQuery={vaQuery} entity="Veranstaltungen">
            {data && (
              <DataGridPro
                columns={veranstaltungsColumns}
                rows={data}
                checkboxSelection
                pagination
                pageSizeOptions={[5, 10, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                filterModel={filterModel as unknown as GridFilterModel}
                onFilterModelChange={(newFilterModel) => {
                  setFilterModel(newFilterModel);
                  localStorageApi.set(localStorageKeys.THEMA_VA_GRID_FILTERMODEL, newFilterModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  setSortModel(newSortModel);
                  localStorageApi.set(localStorageKeys.THEMA_VA_GRID_SORTMODEL, newSortModel);
                }}
                onRowSelectionModelChange={(newSelection) => setGridSelection(newSelection)}
                rowSelectionModel={gridSelection}
                disableRowSelectionOnClick
                sx={{
                  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                    outline: 'none',
                  },
                  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                    outline: 'none',
                  },
                }}
                getRowId={(veranstaltung) => veranstaltung.akaVeranstaltungId}
              />
            )}
          </TrpcLoadingInfo>
        </Stack>
      </Grid2>
    </Grid2>
  );
};
