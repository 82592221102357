import { healthLevel, type Veranstaltung } from '../../../dtos';
import { trpc } from '../../../trpc';
import { Cancel, CheckCircle, Info, Warning } from '@mui/icons-material';
import { type GridRenderCellParams } from '@mui/x-data-grid-pro';

export const FehlerCell: React.FC<GridRenderCellParams<Veranstaltung>> = (params: GridRenderCellParams<Veranstaltung>) => {
  const healthQuery = trpc.veranstaltung.getHealthByVeranstaltung.useQuery({ veranstaltungId: params.row.id });

  // TODO: Error Handling (needs some Absprache with Laura)
  if (healthQuery.isLoading || !healthQuery.data) {
    return <p>...loading...</p>;
  }

  const checkRes = healthQuery.data;

  if (checkRes.some(({ type }) => type === healthLevel.ERROR)) {
    return <Cancel color="error" />;
  }

  if (checkRes.some(({ type }) => type === healthLevel.WARNING)) {
    return <Warning color="warning" />;
  }

  if (checkRes.some(({ type }) => type === healthLevel.INFO)) {
    return <Info color="info" />;
  }

  return <CheckCircle color="success" />;
};
